import styles from './Rating.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as solidStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { ReactNode } from 'react';

type Props = {
  value: number;
  max?: number;
  min?: number;
};

const StarsRating = ({ value, max = 5, min = 1 }: Props) => {
  const stars: ReactNode[] = [];
  const rounded = Math.floor(value * 10) / 10;
  let i;
  for (i = min; i <= rounded; i++)
    stars.push(<FontAwesomeIcon key={i} icon={solidStar} />);

  if (i <= max && i > rounded && rounded > i - 1)
    stars.push(<FontAwesomeIcon key={i++} icon={faStarHalfAlt} />);

  for (; i <= max; i++)
    stars.push(<FontAwesomeIcon key={i} icon={faStar} />);

  const [title] = useSanaTexts(['Rating_Title']).texts;

  return (
    <span className={styles.stars} title={makeSimpleText(title, [value])}>
      {stars}
    </span>
  );
};

export default StarsRating;
